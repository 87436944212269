import { Component } from 'react';
import logo from './logo-rsuntan.png';
import styles from './App.css';
import ReactToPrint  from 'react-to-print';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

class App extends Component {
  
  constructor(props) {

    super(props);
    this.state = {
      digit: 1,
      startDate: new Date(),
      count: 1,
      date: new Date().toLocaleDateString(['ban', 'id']),
    };
  }

  setDigit=(e)=>
  {
    this.setState({count:e.target.value})
  }

  componentDidMount = () => {
    setInterval(() => {
      this.setState(prevState =>({

      }));
    }, 10000);
  };
     
  render() {
    const pageStyle = `
    @media print {
      @page { 
        size: Potrait; 
        margin: 0mm; 
      }       
    }
  }
`;



const minus = () => {
  if(this.state.count > 1) {
    this.setState({
      count: --this.state.count
    })
  }
  else {
    this.setState({
      count: this.state.count
    })
  }
}

  const {count} = this.state;
  const {startDate} = this.state;


const myDate = new Date(startDate);
const dayOfMonth = myDate.getDate();
const month = myDate.getMonth();
const year = myDate.getFullYear();

function pad(n) {
    return n<10 ? '0'+n : n
}

const ddmmyyyy = pad(dayOfMonth) + "/" + pad(month + 1) + "/" + year;

  return (

<div className="App">
  <header className="App-header">
  <b><h1 style={{fontSize: "45px"}} className="text-center"><b>PENGAMBILAN NOMOR ANTRIAN <br></br> REGISTRASI POLIKLINIK</b></h1></b>
    <img src={logo} width={250} height={250} alt="logo" />
    <h1 style={{fontSize: "10px"}}>
      <DatePicker className="form-control" selected={startDate} onChange={(startDate) => this.setState({startDate})} dateFormat="dd/MM/yyyy" />
    </h1>
    <div className='fa'>
    <h1 style={{fontSize: "10px"}}>
      <input className="form-control" min="1" type="number" value={count} onChange={this.setDigit} required/>
    </h1>
    </div>

<div className="container" ref={el=>(this.componentRef=el)}>
  <div className="row row-cols-1">
    <div className="col">
      <b><h1 style={{fontSize: "10px"}} className="text-center"><b>PENDAFTARAN <br></br> RS UNIVERSITAS TANJUNGPURA</b></h1></b>
      </div>
      <div className="col"><h1 style={{fontSize: "10px"}} className="text-center"><b>{ddmmyyyy}</b></h1></div>
    <div className="col"><h1 style={{fontSize: "10px"}} className="text-center"><b>ANTRIAN REGISTRASI POLIKLINIK</b></h1></div>
    <div className="col"> <h1 className="text-center" style={{fontSize: "40px"}}>{count}</h1></div>
    <br></br>
    <br></br>
    <div className="col">        
       <h1 style={{fontSize: "8px"}} className="text-center">
             <b>Registrasi : 
             <br></br>Senin - Kamis : 07.30 - 11.00
             <br></br>Jum'at : 07.30 - 10.00</b>
       </h1></div>
  </div>
</div>


<div onClick={() => this.setState({ count: ++this.state.count })}>
<ReactToPrint 
         pageStyle={pageStyle}
         trigger={()=>{
           return <button type="button" className="btn btn-warning btn-lg">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; <i className="bi bi-printer"></i> Print &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</button>
         }}
         content= {()=>this.componentRef}
         documentTitle='Nomor Antrian'
         onAfterPrint={()=>{console.log('document printed')}}
         />
   </div>
   <hr></hr>
<div className="btn-group">
  <button type="button" className="btn btn-primary btn-lg" onClick={() => this.setState({ count: ++this.state.count })}>&emsp;&emsp;<i className="bi bi-plus-circle"></i> Next &emsp;&emsp;</button>
  <button type="button" className="btn btn-dark btn-lg" onClick={minus}>&emsp;&emsp;<i className="bi bi-dash-circle"></i> Back &emsp;&emsp;</button>
</div>
<hr></hr>
<div>
 <button type="button" className="btn btn-danger btn-lg" onClick={() => this.setState({ count: this.state.count = 1 })}>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; <i className="bi bi-arrow-clockwise"></i> Reset &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</button>        
</div>
      </header>
    </div>

 
  );
}
}

export default App;
